import { Auth, GenericResponse } from "../models";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { ActivatedRoute } from '@angular/router';
import {
  AuthLogin,
  AvailableMarketplaces,
  CheckDiscountCode,
  EmailConfirm,
  EmailConfirmLink,
  ExchangeAmazonCode,
  ForgotPassword,
  GetAvailablePackages,
  PackageSelection,
  Register,
  ResetPassword,
  SaveMarketplaceInfo,
  SendEmailConfirmationLink,
  GetAddressCoupon
} from "../actions/auth.actions";
import { tap } from "rxjs/operators";

@State<Auth.AuthState>({
  name: "AuthState",
  defaults: {
    loginResponse: null,
    forgotPasswordResponse: null,
    registerResponse: null,
    emailConfirmResponse: null,
    availablePackages: [],
    discountInfo: null,
    availableMarketplaces: [],
    availableMarketplaces2: null,
    amazonInfo: null,
    addressCoupon: null,
  },
})
@Injectable()
export class AuthState {
  constructor(private authService: AuthService, private route: ActivatedRoute) {}

  @Selector()
  static getLoginResponse({
    loginResponse,
  }: Auth.AuthState): GenericResponse<Auth.LoginResponse> {
    return loginResponse;
  }

  @Selector()
  static getForgotPasswordResponse({
    forgotPasswordResponse,
  }: Auth.AuthState): GenericResponse<Auth.ForgotPasswordResponse> {
    return forgotPasswordResponse;
  }

  @Selector()
  static getRegisterResponse({
    registerResponse,
  }: Auth.AuthState): GenericResponse<Auth.RegisterResponse> {
    return registerResponse;
  }

  @Selector()
  static getEmailConfirmResponse({
    emailConfirmResponse,
  }: Auth.AuthState): Auth.EmailConfirmResponse {
    return emailConfirmResponse;
  }

  @Selector()
  static availablePackages({
    availablePackages,
  }: Auth.AuthState): Auth.PackageInfo[] {
    return availablePackages;
  }

  @Selector()
  static availableMarketplaces({
    availableMarketplaces,
  }: Auth.AuthState): Auth.AvailableMarketPlaceResponse[] {
    return availableMarketplaces;
  }

  @Selector()
  static availableMarketplaces2({
    availableMarketplaces2,
  }: Auth.AuthState): Auth.AvailableMarketPlaceResponse {
    return availableMarketplaces2;
  }

  @Selector()
  static amazonInfo({ amazonInfo }: Auth.AuthState): Auth.AmazonResponse {
    return amazonInfo;
  }

  @Selector()
  static addressCoupon({ addressCoupon }: Auth.AuthState): Auth.AddressCouponDetail {
    return addressCoupon;
  }

  @Selector()
  static discountInfo({ discountInfo }: Auth.AuthState): {
    name: string;
    amount: number;
  } {
    return discountInfo;
  }

  // Modify your function to include setting language based on URL parameter
  @Action(AuthLogin)
  authLogin(
    { patchState }: StateContext<Auth.AuthState>,
    { payload }: AuthLogin
  ) {
    // Fetching the lang parameter from the URL
    const langParam = this.route.snapshot.queryParamMap.get('lang');

    const languageToSet = langParam || 'tr'; // Use 'tr' as default if langParam is null

    return this.authService.login(payload).pipe(
      tap((res) => {
        patchState({ loginResponse: res });
        localStorage.setItem(
          'isMarketplaceAdded',
          res.Result.isMarketplaceAdded ? '1' : '0'
        );
        localStorage.setItem(
          'isSubscriptionSelected',
          res.Result.isSubscriptionSelected ? '1' : '0'
        );
        localStorage.setItem('language', languageToSet); // Set language based on URL parameter or default value
      })
    );
  }

  @Action(ForgotPassword)
  forgotPassword(
    { patchState }: StateContext<Auth.AuthState>,
    { payload }: ForgotPassword
  ) {
    return this.authService.forgotPassword(payload).pipe(
      tap((res) => {
        patchState({ forgotPasswordResponse: res });
      })
    );
  }

  setLocalStorage(res) {
    localStorage.setItem(
      "isMarketplaceAdded",
      res.Result.isMarketplaceAdded ? "1" : "0"
    );
    localStorage.setItem(
      "isSubscriptionSelected",
      res.Result.isSubscriptionSelected ? "1" : "0"
    );

    localStorage.setItem("accessToken", res.Result.accessToken);
    localStorage.setItem("refreshToken", res.Result.refreshToken);
  }

  @Action(Register)
  register(
    { patchState }: StateContext<Auth.AuthState>,
    { payload }: Register
  ) {
    return this.authService.register(payload).pipe(
      tap((res) => {
        this.setLocalStorage(res);
        patchState({ registerResponse: res });
      })
    );
  }

  @Action(EmailConfirm)
  emailConfirm(
    { patchState }: StateContext<Auth.AuthState>,
    { payload }: EmailConfirm
  ) {
    return this.authService.emailConfirm(payload).pipe(
      tap((res) => {
        patchState({ emailConfirmResponse: res });
      })
    );
  }

  @Action(SendEmailConfirmationLink)
  sendEmailConfirmationLink(
    { patchState }: StateContext<Auth.AuthState>,
    { payload }: SendEmailConfirmationLink
  ) {
    return this.authService.sendEmailConfirmationLink({ email: payload });
  }

  @Action(ResetPassword)
  resetPassword(
    { patchState }: StateContext<Auth.AuthState>,
    { payload }: ResetPassword
  ) {
    return this.authService.resetPassword(payload);
  }

  @Action(EmailConfirmLink)
  emailConfirmLink(
    { patchState }: StateContext<Auth.AuthState>,
    { payload }: EmailConfirmLink
  ) {
    return this.authService.confirmEmailLink(payload);
  }

  @Action(GetAvailablePackages)
  getAvailablePackages({ patchState }: StateContext<Auth.AuthState>) {
    return this.authService.AvailablePackages().pipe(
      tap((res) => {
        patchState({ availablePackages: res.Result });
      })
    );
  }

  @Action(CheckDiscountCode)
  checkDiscountCode(
    { patchState }: StateContext<Auth.AuthState>,
    { payload }: CheckDiscountCode
  ) {
    return this.authService.checkDiscountCode(payload).pipe(
      tap((res) => {
        patchState({ discountInfo: res.Result });
      })
    );
  }

  @Action(PackageSelection)
  packageSelection(
    { patchState }: StateContext<Auth.AuthState>,
    { payload }: PackageSelection
  ) {
    return this.authService.packageSelection(payload,1);
  }

  @Action(AvailableMarketplaces)
  availableMarketplaces(
    { patchState }: StateContext<Auth.AuthState>,
    { payload }: AvailableMarketplaces
  ) {
    return this.authService.AvailableMarketplaces(payload).pipe(
      tap((res) => {
        patchState({ availableMarketplaces: res.Result });
      })
    );
  }

  @Action(SaveMarketplaceInfo)
  saveMarketplaceInfo(
    { patchState }: StateContext<Auth.AuthState>,
    { payload, type }: SaveMarketplaceInfo
  ) {
    return this.authService.saveMarketplaceInfo(payload,type);
  }

  @Action(ExchangeAmazonCode)
  exchangeAmazonCode(
    { patchState }: StateContext<Auth.AuthState>,
    { payload }: ExchangeAmazonCode
  ) {
    return this.authService.ExchangeAmazonCode(payload, localStorage.getItem("selectedMarketPlaceCountry")).pipe(
      tap((res) => {
        patchState({ amazonInfo: res.Result });
      })
    );
  }

  @Action(GetAddressCoupon)
  getAddressCoupon({ patchState }: StateContext<Auth.AuthState>) {
    return this.authService.AddressCoupon().pipe(
      tap((res) => {
        patchState({ addressCoupon: res.Result });
      })
    );
  }
}
