import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  DatePipe,
  PathLocationStrategy,
  HashLocationStrategy,
  LocationStrategy,
} from "@angular/common";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./layout/header/header.component";
import { PageLoaderComponent } from "./layout/page-loader/page-loader.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { RightSidebarComponent } from "./layout/right-sidebar/right-sidebar.component";
import { DynamicScriptLoaderService } from "./services/dynamic-script-loader.service";
import { RightSidebarService } from "./services/rightsidebar.service";
import { WINDOW_PROVIDERS } from "./services/window.service";
import { CalendarService } from "./apps/calendar/calendar.service";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { NgxMaskModule } from "ngx-mask";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatDialogModule } from "@angular/material/dialog";
import { SimpleDialogComponent } from "./ui/modal/simpleDialog.component";
import { DialogformComponent } from "./ui/modal/dialogform/dialogform.component";
import { BottomSheetOverviewExampleSheet } from "./ui/bottom-sheet/bottom-sheet.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { AgmCoreModule } from "@agm/core";
import { ClickOutsideModule } from "ng-click-outside";
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClient,
} from "@angular/common/http";
import { ContactsService } from "./apps/contacts/contacts.service";
import { AdvanceTableService } from "./tables/advance-table/advance-table.service";
import { NgxSpinnerModule } from "ngx-spinner";
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from "ngx-perfect-scrollbar";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { MatSelectModule } from "@angular/material/select";
import { NgxRestModule } from "ngx-rest-service";
import { NgxsModule } from "@ngxs/store";
import {
  AccountManagementState,
  AuthState,
  DashboardState,
  DropyHouseState,
  ProductInventoryState,
  RestrictionState,
} from "./shared/states";
import { ErrorInterceptor } from "./shared/utils/error.interceptor";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { TokenInterceptor } from "./shared/utils/token.interceptor";
import { BestSellerState } from "./shared/states/best-seller.state";
import { ImportedToStoreState } from "./shared/states/inventory/imported-to-store.state";
import { DoesntMeetCriteriaState } from "./shared/states/inventory/doesnt-meet-criteria.state";
import { InQueueState } from "./shared/states/inventory/in-queue.state";
import { PendingApprovalState } from "./shared/states/inventory/pending-approval.state";
import { ReceivedPaymentsState } from "./shared/states/account/my-received-payments-state";
import { SafePipe } from "./shared/utils/safe.pipe";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { DateAgoPipe } from "./pipes/date-ago.pipe";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslationService } from "./services/translate.service";
import { PreWelcomeComponent } from "./invitation/components/pre-welcome/pre-welcome.component";

export function translationInitializer(translationService: TranslationService) {
  return function () {
    return translationService.init("en");
  };
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    SimpleDialogComponent,
    DialogformComponent,
    BottomSheetOverviewExampleSheet,
    SafePipe,
    DateAgoPipe,
    PreWelcomeComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatSidenavModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatMenuModule,
    NgxSpinnerModule,
    ClickOutsideModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatTooltipModule,
    MatSnackBarModule,
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "YOUR API KEY",
    }),
    MatSelectModule,
    NgxRestModule.forRoot({
      apiBaseUrl: "https://api.dropyonline.com/api/v1/",
      //{ apiBaseUrl: "https://localhost:5005/api/v1/" },
    }),
    NgxsModule.forRoot([
      AuthState,
      AccountManagementState,
      RestrictionState,
      ProductInventoryState,
      BestSellerState,
      ImportedToStoreState,
      DoesntMeetCriteriaState,
      InQueueState,
      PendingApprovalState,
      ReceivedPaymentsState,
      DashboardState,
      DropyHouseState,
    ]),
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: translationInitializer,
      deps: [TranslationService],
      multi: true,
    },
    { provide: "apiUrl", useValue: "https://api.dropyonline.com/api/v1/" },
    //{ provide: "apiUrl", useValue: "https://localhost:5005/api/v1/" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    DynamicScriptLoaderService,
    RightSidebarService,
    AdvanceTableService,
    CalendarService,
    ContactsService,
    WINDOW_PROVIDERS,
    SafePipe,
    DatePipe,
    DateAgoPipe,
  ],
  entryComponents: [
    SimpleDialogComponent,
    DialogformComponent,
    BottomSheetOverviewExampleSheet,
  ],
  bootstrap: [AppComponent],
  exports: [DateAgoPipe],
})
export class AppModule {}
