import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  AuthGuard,
  isActiveAccountGuard,
  isActiveAmazonAccessGuard,
} from "./shared/guards";
import { AccountResolver, MarketPlaceResolver } from "./shared/resolvers";

const routes: Routes = [
  {
    path: "authentication",
    loadChildren: () =>
      import("./authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "dashboard",
    canActivate: [AuthGuard],
    resolve: [MarketPlaceResolver, AccountResolver],
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path: "emailconfirmpage",
    loadChildren: () =>
      import("./emailconfirmpage/emailconfirmpage.module").then(
        (m) => m.EmailConfirmPageModule
      ),
  },
  {
    path: "store",
    canActivate: [AuthGuard],
    resolve: [MarketPlaceResolver],
    loadChildren: () =>
      import("./store/store.module").then((m) => m.StoreModule),
  },
  {
    path: "category",
    canActivate: [AuthGuard],
    resolve: [MarketPlaceResolver],
    loadChildren: () =>
      import("./category/category.module").then((m) => m.CategoryModule),
  },
  {
    path: "order",
    canActivate: [AuthGuard],
    resolve: [MarketPlaceResolver],
    loadChildren: () =>
      import("./order/order.module").then((m) => m.OrderModule),
  },
  {
    path: "account",
    canActivate: [AuthGuard],
    resolve: [MarketPlaceResolver],
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
  },
  {
    path: "help",
    canActivate: [AuthGuard],
    resolve: [MarketPlaceResolver],
    loadChildren: () => import("./help/help.module").then((m) => m.HelpModule),
  },
  {
    path: "best-sellers",
    canActivate: [AuthGuard, isActiveAccountGuard],
    resolve: [MarketPlaceResolver],
    loadChildren: () =>
      import("./best-sellers/best-sellers.module").then(
        (m) => m.BestSellersModule
      ),
  },
  {
    path: "reports",
    canActivate: [AuthGuard],
    resolve: [MarketPlaceResolver],
    loadChildren: () =>
      import("./reports/reports.module").then((m) => m.ReportsModule),
  },
  {
    path: "dropy",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./dropy/dropy.module").then((m) => m.DropyModule),
  },
  {
    path: "restrictions",
    canActivate: [AuthGuard],
    resolve: [MarketPlaceResolver],
    loadChildren: () =>
      import("./restrictions/restrictions.module").then(
        (m) => m.RestrictionsModule
      ),
  },
  {
    path: "feedback",
    canActivate: [AuthGuard],
    resolve: [MarketPlaceResolver],
    loadChildren: () =>
      import("./feedback/feedback.module").then((m) => m.FeedbackModule),
  },
  {
    path: "invitation",
    loadChildren: () =>
      import("./invitation/invitation.module").then((m) => m.InvitationModule),
  },
  {
    path: "search",
    //canActivate: [AuthGuard, isActiveAccountGuard, isActiveAmazonAccessGuard],
    canActivate: [AuthGuard, isActiveAccountGuard],
    resolve: [MarketPlaceResolver],
    loadChildren: () =>
      import("./search/search.module").then((m) => m.SearchModule),
  },
  {
    path: "",
    redirectTo: "authentication",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    MarketPlaceResolver,
    AccountResolver,
    isActiveAccountGuard,
  ],
})
export class AppRoutingModule {}
